import React, { useEffect, useState } from "react";

const data = [
  {
    id: 1,
    title: "Custom Designs To Fit Your Space",
    image: "/images/cabinets/kitchen/kitchen3_1.jpg",
  },
  {
    id: 2,
    title: "Customer Satisfaction",
    image: "/images/wardrobe1_resized.jpg",
  },
  {
    id: 3,
    title: "Quick and efficient",
    image: "/images/partitions/Partition3.jpg",
  },
  {
    id: 4,
    title: "Creative and original",
    image: "/images/OutDoor.jpeg",
  },
];

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () =>
        setCurrentSlide((prev) => (prev === data.length - 1 ? 0 : prev + 1)),
      2000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col h-screen md:h-[calc(100vh-9rem)] lg:h-[calc(100vh-4rem)] lg:flex-row bg-fuchsia-50">
      {/* Text Container */}
      <div className="flex-1 flex items-center justify-center flex-col gap-8 text-orange-500 font-bold">
        <h1 className="text-2xl text-center uppercase p-4 md:p-10 md:text-2xl xl:text-3xl">
          {data[currentSlide].title}
        </h1>
      </div>
      {/* Image Container */}
      <div className="w-full flex justify-center items-center mt-2">
        <div className="flex items-center justify-center w-[90vw] max-w-[1200px] h-[80vh] ">
          <img
            src={data[currentSlide].image}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain", // Keeps image centered and fills container
              borderRadius: "8px",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Slider;
