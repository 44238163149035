import React from "react";
import Slider from "../components/Slider";
import Services from "../components/Services";
import ContactPage from "../components/Contact";

const landing = () => {
  return (
    <React.Fragment>
      <Slider />
      <Services />
      <ContactPage />
    </React.Fragment>
  );
};

export default landing;
