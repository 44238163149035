import React, { useState } from "react";
import { Link } from "react-router-dom";

const links = [
  { id: 1, title: "Homepage", url: "/" },
  { id: 2, title: "Cabinets", url: "/cabinets" },
  { id: 3, title: "Wood", url: "/wood" },
  { id: 4, title: "Contact", url: "/contact" },
];

function Menu() {
  const [open, setOpen] = useState(false);

  return (
    <div>
      {!open ? (
        <img
          src="/images/open.png"
          alt=""
          width={20}
          height={20}
          onClick={() => setOpen(true)}
        />
      ) : (
        <img
          src="/images/close.png"
          alt=""
          width={20}
          height={20}
          onClick={() => setOpen(false)}
        />
      )}
      {open && (
        <div className="bg-orange-500 text-white fixed left-0 top-24 w-full h-[calc(100vh-6rem)] flex flex-col gap-8 items-center justify-center text-3xl z-10">
          {links.map((item) => (
            <Link to={item.url} key={item.id} onClick={() => setOpen(false)}>
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default Menu;
