import React, { useState } from "react";
import { Link } from "react-router-dom";

const services = [
  {
    id: 1,
    title: "Cabinets",
    description: "High-quality cabinets for your home.",
    img: "/images/smallkitchen.jpg",
    slug: "cabinets",
  },
  {
    id: 2,
    title: "Wood Items",
    description: "Different wood products.",
    img: "/images/OutDoor.jpeg",
    slug: "wood",
  },
  {
    id: 3,
    title: "Partitioning",
    description: "Custom partitioning solutions for spaces",
    img: "/images/smallpartition.jpg",
    slug: "partitions",
  },
];

const Services = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="w-screen text-orange-500 ">
      <div className="flex flex-1 flex-col items-center text-center my-8">
        <h1 className="font-bold text-3xl text-center md:text-4xl xl:text-5xl m-5 ">
          Services
        </h1>
        <span className="align-items place-items-center">
          These are the services we currently offer
        </span>
      </div>
      {/* Wrapper */}
      <div className="w-max  flex flex-col lg:flex-row font-semibold md:h-1/2 ">
        {/* Single Item */}
        {services.map((s) => (
          <div
            key={s.id}
            className="w-screen items-center p-3 hover:bg-fuchsia-50 md:w-[50vw] xl:w-[33vw] xl:h-[90vh] "
          >
            {/* Image Container */}
            <div className="relative flex-1 w-full h-[60vh] ">
              <img src={s.img} alt="" className="object-cover w-full h-full" />
              <Link
                to={s.slug}
                onClick={() => setOpen(false)}
                className="absolute inset-0 flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-30 text-white font-semibold hover:bg-opacity-50 transition-opacity duration-300 opacity-0 hover:opacity-100"
              >
                Explore
              </Link>
            </div>
            {/* Text Container */}
            <div className="flex-1 flex flex-col gap-3 items-center justify-center text-center ">
              <h1 className="uppercase text-xl font-bold xl:text-2xl 2xl:text-3xl">
                {s.title}
              </h1>
              <span className="text-gray-600 font-bold">{s.description}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
