export const services = [
  {
    id: 1,
    title: "Cabinets",
    description: "High-quality cabinets for your home.",
    img: "/images/smallkitchen.jpg",
    slug: "cabinets",
  },
  {
    id: 2,
    title: "Wood",
    description: "Wood Products.",
    img: "/images/OutDoor.jpg",
    slug: "wood",
  },
  {
    id: 3,
    title: "Partitioning",
    description: "Custom partitioning solutions for spaces",
    img: "/images/smallpartition.jpg",
    slug: "partitioning",
  },
];

export const wood = [
  {
    id: 1,
    slug: "flowerPots",
    title: "Pots",
    description:
      "Savor the taste of perfection with our exquisite Italian handmade pasta menu.",
    img: "/images/flowerPots/FlowerPot1.jpg",
  },
  {
    id: 2,
    slug: "gardenChairs",
    title: "Chairs",
    description:
      "Burger Bliss: Juicy patties, bold flavors, and gourmet toppings galore.",
    img: "/images/gardenChairs/GardenChair1.jpg",
  },
  {
    id: 3,
    slug: "gardenTables",
    title: "Tables",
    description: "ShopFronts done professionally and speedily",
    img: "/images/gardenTables/GardenTable1.jpg",
  },
];

export const cabinets = [
  {
    id: 1,
    slug: "kitchens",
    title: "Kitchens",
    desc: "Many beautiful designs",
    img: "/images/cabinets/kitchen/kitchen_main.jpg",
  },
  {
    id: 2,
    slug: "bathrooms",
    title: "Bathrooms",
    desc: "Expertly completed",
    img: "/images/cabinets/bathroom/Bathroom1.jpg",
  },
  {
    id: 3,
    slug: "wardrobes",
    title: "Wardrobes",
    desc: "Creatively done",
    img: "/images/cabinets/wardrobe/wardrobe1.jpg",
  },
];

export const cabinetItems = [
  {
    id: 1,
    title: "PushOpen",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/kitchen/Rynstreet.jpg",
    category: "kitchens",
  },
  {
    id: 2,
    title: "Dark",
    desc: "Island in the middle",
    img: "/images/cabinets/kitchen/kitchen_main.jpg",
    category: "kitchens",
  },
  {
    id: 3,
    title: "Island",
    desc: "Island in the middle",
    img: "/images/cabinets/kitchen/kitchen_2.jpg",
    category: "kitchens",
  },
  {
    id: 4,
    title: "Downlights",
    desc: "With downlights on cupboards",
    img: "/images/cabinets/kitchen/kitchen_2_3.jpg",
    category: "kitchens",
  },

  {
    id: 5,
    title: "Vanity",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/bathroom/bathroom2.jpg",
    category: "bathrooms",
  },
  {
    id: 6,
    title: "Dark",
    desc: "Island in the middle",
    img: "/images/cabinets/bathroom/bathroom1.jpg",
    category: "bathrooms",
  },
  {
    id: 7,
    title: "Complete",
    desc: "Full Cupboards in bathroom",
    img: "/images/cabinets/bathroom/bathroom_3.jpg",
    category: "bathrooms",
  },
  {
    id: 8,
    title: "Sliding doors",
    desc: "No handles and easy push to open cupboards",
    img: "/images/cabinets/wardrobe/wardrobe2.jpg",
    category: "wardrobes",
  },
  {
    id: 9,
    title: "Hanging One Side",
    desc: "Island in the middle",
    img: "/images/cabinets/wardrobe/wardrobe3.jpg",
    category: "wardrobes",
  },
  {
    id: 10,
    title: "Standard doors",
    desc: "Full Cupboards in bathroom",
    img: "/images/cabinets/wardrobe/wardrobe4.jpg",
    category: "wardrobes",
  },
];

export const woodItems = [
  {
    id: 1,
    title: "FP1",
    desc: "No handles and easy push to open cupboards",
    img: "/images/flowerPots/FlowerPot1.jpg",
    category: "flowerPots",
  },
  {
    id: 2,
    title: "FP2",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot2.jpg",
    category: "flowerPots",
  },

  {
    id: 4,
    title: "GC1",
    desc: "With downlights on cupboards",
    img: "/images/gardenChairs/GardenChair1.jpg",
    category: "gardenChairs",
  },

  {
    id: 6,
    title: "GC2",
    desc: "Island in the middle",
    img: "/images/gardenChairs/GardenChair2.jpg",
    category: "gardenChairs",
  },
  {
    id: 5,
    title: "GC3",
    desc: "No handles and easy push to open cupboards",
    img: "/images/gardenChairs/GardenChair3.jpg",
    category: "gardenChairs",
  },
  {
    id: 7,
    title: "GT1",
    desc: "Full Cupboards in bathroom",
    img: "/images/gardenTables/GT1Small.jpg",
    category: "gardenTables",
  },
  {
    id: 8,
    title: "GT2",
    desc: "No handles and easy push to open cupboards",
    img: "/images/gardenTables/GT2Small.jpg",
    category: "gardenTables",
  },
  {
    id: 9,
    title: "GT3",
    desc: "Island in the middle",
    img: "/images/gardenTables/GT3Small.jpg",
    category: "gardenTables",
  },
  {
    id: 10,
    title: "GT4",
    desc: "Full Cupboards in bathroom",
    img: "/images/gardenTables/GT4Small.jpg",
    category: "gardenTables",
  },
  {
    id: 11,
    title: "D1",
    desc: "No handles and easy push to open cupboards",
    img: "/images/flowerPots/FlowerPot4.jpg",
    category: "flowerPots",
  },
  {
    id: 12,
    title: "D2",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot5.jpg",
    category: "flowerPots",
  },
  {
    id: 13,
    title: "D3",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot6.jpg",
    category: "flowerPots",
  },
  {
    id: 14,
    title: "D2",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot7.jpg",
    category: "flowerPots",
  },
  {
    id: 15,
    title: "D3",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot8.png",
    category: "flowerPots",
  },
  {
    id: 3,
    title: "D3",
    desc: "Island in the middle",
    img: "/images/flowerPots/FlowerPot3.jpg",
    category: "flowerPots",
  },
];
