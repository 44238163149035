import React from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import LandingPage from "./pages/landing";
import Cabinetcategories from "./pages/cabinets/cabinetcategories";
import CategoryPage from "./pages/categoryPage";
import WoodCategories from "./pages/wood/woodcategories";
import PartitionPage from "./pages/partitioning/partitioncategories";
import ContactPage from "./components/Contact";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Header />
      <Navbar />

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/cabinets" element={<Cabinetcategories />} />
        <Route path="/cabinets/:category" element={<CategoryPage />} />
        <Route path="/wood" element={<WoodCategories />} />
        <Route path="/wood/:category" element={<CategoryPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/partitions" element={<PartitionPage />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
