import React from "react";

const ContactPage = () => {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "bbc163eb-92e2-4a4d-8c22-ae81d2ae8fd4");
    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className="min-h-screen w-screen mx-auto grid grid-cols-12 gap-1 bg-orange-500 p-1">
      {/* Left Column: Contact Form */}
      <div className="col-span-12 md:col-span-6 p-4">
        <form
          onSubmit={onSubmit} // Use onSubmit instead of onClick
          className="bg-white p-6 rounded shadow-md"
        >
          <h2 className="text-xl font-bold mb-4">Contact Us</h2>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            required
            className="w-full p-2 mb-3 border border-gray-300 rounded"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            required
            className="w-full p-2 mb-3 border border-gray-300 rounded"
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            required
            className="w-full p-2 mb-3 border border-gray-300 rounded"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            required
            className="w-full p-2 mb-3 border border-gray-300 rounded"
          />
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            Send Message
          </button>
        </form>
        <span>{result}</span>
      </div>

      {/* Right Column: Directions */}
      <div className="col-span-12 md:col-span-6 flex flex-col items-center justify-center text-white text-center p-4">
        <div className="section-title mb-3">
          <div className="lg:text-3xl pb-3 font-bold">Get In Touch</div>
          <p>Directions and contact details</p>
        </div>
        <div className="underline">
          <label>Address :</label>
        </div>
        <div className="pb-4">
          <p>Block C8</p>
          <p>134 Kempston Road, Korsten, Qgebera</p>
          <p>(opp Mercantile Hospital)</p>
        </div>
        <div className="underline">
          <label>Contact :</label>
        </div>
        <div>
          <p>Mobile : 065 925 8198</p>
        </div>
        <div className="underline mt-4">
          <label>Email :</label>
        </div>
        <div>
          <p>admin@abrtech.co.za</p>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
