import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';

const partitions = [
  {
    id: 1,
    slug: "partitions",
    title: "Partition 1",
    desc: "Savor the taste of perfection with our exquisite Italian handmade pasta menu.",
    img: "/images/partitions/Partition1.jpg",
  
  },
  {
    id: 2,
    slug: "partitions",
    title: "Partition 2",
    desc: "Burger Bliss: Juicy patties, bold flavors, and gourmet toppings galore.",
    img: "/images/partitions/Partition2.jpg",
   
  },
 
];

const PartitionPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-4 lg:px-20 xl:px-40 h-[calc(100vh-6rem)] md:h-[calc(100vh-6rem)] flex flex-col md:flex-row items-center relative">
       <button  onClick={()=> navigate(-1)} className="bg-red-500 hover:bg-red-600 text-white rounded-full w-8 h-8 absolute top-2 left-2 flex items-center justify-center">
                            <ArrowBackSharpIcon />
                        </button>
      {partitions.map((item) => (
        <div
          key={item.id}
          className="w-screen items-center p-2 hover:bg-fuchsia-50 md:w-[50vw] xl:w-[33vw] xl:h-[50vh]"
        >
         
          <div className="flex flex-col md:flex-row">
          
            <h1 className="uppercase text-center font-bold text-2xl text-red-500">
              {item.title}
            </h1>
          </div>
     
            <div className="relative flex-1 w-full h-[60vh]">
              <img
                src={item.img}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
      
        </div>
      ))}
   
    </div>
  );
};

export default PartitionPage;
