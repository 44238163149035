import React from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="relative h-20 text-red-500 p-4 flex flex-col md:flex-row items-center justify-between border-b-2 border-b-red-500 md:h-20 lg:px-20 xl:px-10 xl:h-20 font-poppins">
      {/* LEFT LINKS */}
      <div className="hidden md:flex gap-6 flex-1 xl:text-left text-base xl:text-lg font-medium text-gray-800">
        <Link to="/">Home</Link>
        <Link to="/cabinets">Cabinets</Link>
        <Link to="/wood">Wood</Link>
        <Link to="/contact">ContactPage</Link>
      </div>
      {/* LOGO */}
      <div className=" justify-center w-full md:w-auto flex-1 text-3xl md:text-2xl md:font-bold text-center xl:text-4xl font-semibold text-orange-500 h-full flex items-center">
        <a href="/">AbrTech</a>
      </div>
      {/* MOBILE MENU */}
      <div className="md:hidden absolute right-4">
        <Menu />
      </div>
      {/* RIGHT LINKS */}
      <div className="hidden md:flex gap-4 items-center justify-end flex-1 text-gray-700 text-sm">
        <div className="flex items-center gap-2 cursor-pointer bg-orange-300 px-2 py-1 rounded-md">
          <img
            src="/images/phone.png"
            alt="phone icon"
            width={20}
            height={20}
          />
          <span>065 925 8198</span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
