import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cabinetItems, woodItems } from "../data";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

const CategoryPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  let items = [];

  // Determine which items to display based on the category
  if (
    category === "kitchens" ||
    category === "bathrooms" ||
    category === "wardrobes"
  ) {
    items = cabinetItems.filter((item) => item.category === category);
  } else if (
    category === "flowerPots" ||
    category === "gardenChairs" ||
    category === "gardenTables"
  ) {
    items = woodItems.filter((item) => item.category === category);
  }

  return (
    <div className="p-4 gap-4 lg:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center  w-full m-3">
      {items.map((item) => (
        <div className="border-2 border-orange-500 relative" key={item.id}>
          <button
            onClick={() => navigate(-1)}
            className="bg-orange-500 hover:bg-orange-600 text-white rounded-full w-8 h-8 absolute top-2 left-2 flex items-center justify-center"
          >
            <ArrowBackSharpIcon />
          </button>

          <img className="w-full h-auto" src={item.img} alt={item.title} />
        </div>
      ))}
    </div>
  );
};

export default CategoryPage;
