import React from "react";

function Header() {
  return (
    <div className="h-16 bg-orange-500 text-white px-6 flex items-center justify-center text-center font-semibold text-md md:text-lg cursor-pointer font-poppins tracking-wide">
      Kitchens, Wardrobes, Bathrooms, Woodwork Items
    </div>
  );
}

export default Header;
